import React, { useState } from "react";
import { Box, Button, Chip } from "@mui/material";
import "./style.css";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { selectFilter } from "../../redux/selectors";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../redux/actions";
import { UpdateBoard } from "../../services";
import { useParams } from "react-router-dom";
import CommonDialog from "../../utils/Dialog";

function Filters(props) {
  const { dataType, title, onClick, value, forceUpdate } = props;
  const dispatch = useDispatch();
  const FilterData = useSelector(selectFilter);
  const { id } = useParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const modifyDataType = (dataType) => {
    if (dataType?.includes("Date")) {
      return "Date";
    } else if (dataType?.includes("String")) {
      return "String";
    } else if (dataType?.includes("Int64")) {
      return "Number";
    } else if (dataType?.includes("Float64")) {
      return "Number";
    } else {
      return "Other";
    }
  };

  const handleCrossButtonClick = async () => {
    const updatedFilters = FilterData.filter(
      (filter) => filter.colName !== title
    );
    console.log(updatedFilters);
    const data = {
      boardId: id,
      data: {
        filters: updatedFilters,
      },
    };
    
    const apiResponse = await UpdateBoard(data);
    dispatch(setFilter(updatedFilters));
    forceUpdate();
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

   const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const dialogActions = [
    {
      label: "Cancel",
      color: "primary",
      onClick: handleDialogClose,
    },
    {
      label: "Clear All",
      color: "warning",
      onClick: () => {
        handleCrossButtonClick();
        handleDialogClose();
      },
    },
  ];

  return (
    <Box
      sx={{
        position: "relative",
        marginRight: "12px",
      }}
    >
      <Box
        className="filter-box"
        component={Button}
        sx={{
          color: "unset",
          fontWeight: "400",
          bgcolor: "#fff",
          position: "relative",
          padding: "5px",
          display: "flex",
        }}
        onClick={onClick}
      >
        <div className="flexee">
          {title === "Add Filter" ? (
            <AddRoundedIcon sx={{ fontSize: "18px" }} />
          ) : (
            ""
          )}
          <div className="title">
            {title === "Add Filter" ? "Filter" : title + " :"}
          </div>

          {Array.isArray(value) &&
            value?.slice(0, 2).map((key, index) => (
              <Box
                sx={{
                  marginLeft:
                    index === 1 && modifyDataType(dataType) === "Date"
                      ? "0px"
                      : "10px",
                }}
                key={key}
              >
                <Chip label={key} sx={{ m: "2px" }} size="small" />
                {index === 0 && modifyDataType(dataType) === "Date" && (
                  <span style={{ paddingLeft: "4px" }}>-</span>
                )}
              </Box>
            ))}

          {value?.length > 2 && (
            <span
              style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
            >
              {`+${value?.length - 2}`}
            </span>
          )}
        </div>
      </Box>
      {title !== "Add Filter" && (
        <Box
          sx={{
            cursor: "pointer",
            color: "#000",
            top: "-9px",
            right: "-7px",
            position: "absolute",
          }}
          onClick={handleDialogOpen}
        >
          <CloseRoundedIcon
            sx={{
              fontSize: "18px",
              backgroundColor: "#d3d3d3",
              borderRadius: "50%",
              padding: "2px",
            }}
          />
        </Box>
      )}
      <CommonDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        title="Clear Filters"
        content="Do you confirm your decision to clear all the filters?"
        actions={dialogActions}
      />
    </Box>
  );
}

export default Filters;
